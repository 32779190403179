import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
  TableHead,
  Chip,
  Box,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableFooter,
  IconButton,
} from '@material-ui/core';

import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import CircularProgress from '@mui/material/CircularProgress';
import AuthService from '../../../services/auth.service';
import { privateRequest } from '../../../requestMethods';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// const categories = [
//   {
//     name: 'Combos',
//     temps_sortie: '12 minutes',
//     nbr_cmd: 433,
//     quantity: 3442,
//     fast_trank: 0.14,
//     speciaux: 3.33,
//     normaux: 13.52,
//   },
//   {
//     name: 'Glaces',
//     temps_sortie: '15 minutes',
//     nbr_cmd: 63,
//     quantity: 2419,
//     fast_trank: 2.12,
//     speciaux: 6.33,
//     normaux: 13.52,
//   },
//   {
//     name: 'Bowl',
//     temps_sortie: '12 minutes',
//     nbr_cmd: 23,
//     quantity: 3442,
//     fast_trank: 4.34,
//     speciaux: 3.33,
//     normaux: 13.52,
//   },
//   {
//     name: 'Tacos',
//     temps_sortie: '12 minutes',
//     nbr_cmd: 433,
//     quantity: 3442,
//     fast_trank: 0.14,
//     speciaux: 3.33,
//     normaux: 13.52,
//   },
// ];

const TempsSortieCategory = ({ datedebut, datefin, idResto }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const currentUser = AuthService.getCurrentUser();
  const UserRoles = currentUser ? currentUser?.roles : null;
  const [mesRestoParams, setMesRestoParams] = useState([]);
  const [categories, setCategories] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categories.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getListeRestoParams = () => {
    privateRequest
      .get(
        `/api/v1/dashboard/findAllRestosGuest/${currentUser?.id_guest}`,
      )
      .then((response) => {
        setMesRestoParams(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (UserRoles === 'guest') {
      getListeRestoParams();
    }
  }, [UserRoles]);

  const retrieveTypeDiscountByRestaurant = () => {
    if (UserRoles === 'guest') {
      privateRequest
        .post(
          `/api/v1/dashboard/FindAllPerformancesCategoryLinesGuest/${currentUser?.id}&${datedebut}&${datefin}`,
          mesRestoParams,
        )
        .then((response) => {
          setCategories(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setIsLoading(true);
      privateRequest
        .get(
          `/api/v1/dashboard/FindAllPerformancesCategoryLines/${currentUser?.id}&${datedebut}&${datefin}&${idResto}`,
        )
        .then((response) => {
          const InitialArray = response.data;
          let id = 1;
          const productsWithId = InitialArray.map((product) => {
            return { ...product, id: id++ };
          });
          setCategories(productsWithId);
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    retrieveTypeDiscountByRestaurant();
  }, [currentUser?.id, datedebut, datefin, mesRestoParams, idResto]);

  const ConvertirMille = (value) => {
    const ValueParseFloat = parseFloat(value);
    const FinalValue = ValueParseFloat.toLocaleString('fr-FR');
    return FinalValue;
  };

  // const ConvertirNumber = (value) => {
  //   const ValueParseInt = parseInt(value, 10);
  //   const FinalValue = ValueParseInt.toLocaleString("fr-FR");
  // return FinalValue;
  // }

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h4">Temps de Sortie Moyen Par Category</Typography>
          </Box>
          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size="50px" />
            </Box>
          )}
          <Box
            sx={{
              overflow: {
                xs: 'auto',
                sm: 'auto',
              },
            }}
          >
            {!isLoading && (
              <Table
                aria-label="custom pagination table"
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">Category</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="">
                        Temps de Sortie Moyen
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="">
                        Nombre de Commandes
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="">
                        Quantité Produits
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="">
                        Fast Trank
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="">
                        %Speciaux
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6" align="">
                        %Normaux
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? categories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : categories
                  ).map((category) => (
                    <TableRow key={category.id}>
                      <TableCell>
                        <Chip
                          sx={{
                            backgroundColor: (theme) => theme.palette.primary.light,
                            color: (theme) => theme.palette.primary.dark,
                            borderRadius: '6px',
                            pl: '3px',
                            pr: '3px',
                          }}
                          size="small"
                          label={category.categorie.toUpperCase()}
                        />
                      </TableCell>

                      <TableCell>
                        <Chip
                          sx={{
                            // backgroundColor:
                            //   category.temps_sortie >= 0 && category.temps_sortie <= 15
                            //     ? (theme) => theme.palette.success.main
                            //     : category.temps_sortie >= 15 && category.temps_sortie <= 30
                            //     ? (theme) => theme.palette.warning.main
                            //     : category.temps_sortie >= 30
                            //     ? (theme) => theme.palette.danger.main
                            //     : 'white',
                            // color: '#fff',
                            backgroundColor:
                              category.temps_sortie >= 0 && category.temps_sortie <= 10
                                ? '#2EB62C'
                                : category.temps_sortie >= 10 && category.temps_sortie <= 20
                                ? '#57C84D'
                                : category.temps_sortie >= 20 && category.temps_sortie <= 30
                                ? '#83D475'
                                : category.temps_sortie >= 30 && category.temps_sortie <= 40
                                ? '#ABE098'
                                : category.temps_sortie >= 40 && category.temps_sortie <= 50
                                ? '#C5E8B7'
                                : // : category.temps_sortie >= 50 && category.temps_sortie <= 60 ? '#FFFFB7'
                                category.temps_sortie >= 50 && category.temps_sortie <= 60
                                ? '#FFF192'
                                : category.temps_sortie >= 60 && category.temps_sortie <= 70
                                ? '#FFEA61'
                                : category.temps_sortie >= 70 && category.temps_sortie <= 80
                                ? '#FFDD3C'
                                : category.temps_sortie >= 80 && category.temps_sortie <= 90
                                ? '#FFD400'
                                : category.temps_sortie >= 90 && category.temps_sortie <= 100
                                ? '#F6BDC0'
                                : category.temps_sortie >= 100 && category.temps_sortie <= 110
                                ? '#F1959B'
                                : category.temps_sortie >= 110 && category.temps_sortie <= 120
                                ? '#F07470'
                                : category.temps_sortie >= 120 && category.temps_sortie <= 130
                                ? '#EA4C46'
                                : category.temps_sortie >= 130
                                ? '#DC1C13'
                                : 'white',
                            color: '#fff',
                            borderRadius: '6px',
                            minWidth: 80,
                          }}
                          size="small"
                          label={ConvertirMille(category.temps_sortie)+' min'}
                        />
                      </TableCell>

                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                          }}
                        >
                          {ConvertirMille(category.nbr_cmd)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                          }}
                        >
                          {ConvertirMille(category.quantity)}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Chip
                          sx={{
                            backgroundColor:
                              category.fast_trank >= 0 && category.fast_trank <= 20
                                ? '#2EB62C'
                                : category.fast_trank >= 20 && category.fast_trank <= 40
                                ? '#57C84D'
                                : category.fast_trank >= 40 && category.fast_trank <= 60
                                ? '#83D475'
                                : category.fast_trank >= 60 && category.fast_trank <= 80
                                ? '#ABE098'
                                : category.fast_trank >= 80 && category.fast_trank <= 100
                                ? '#C5E8B7'
                                : category.fast_trank >= 100 && category.fast_trank <= 120
                                ? '#FFF192'
                                : category.fast_trank >= 120 && category.fast_trank <= 140
                                ? '#FFEA61'
                                : category.fast_trank >= 140 && category.fast_trank <= 160
                                ? '#FFDD3C'
                                : category.fast_trank >= 160 && category.fast_trank <= 180
                                ? '#FFD400'
                                : category.fast_trank >= 180 && category.fast_trank <= 200
                                ? '#F6BDC0'
                                : category.fast_trank >= 200 && category.fast_trank <= 220
                                ? '#F1959B'
                                : category.fast_trank >= 220 && category.fast_trank <= 240
                                ? '#F07470'
                                : category.fast_trank >= 240 && category.fast_trank <= 260
                                ? '#EA4C46'
                                : category.fast_trank >= 260
                                ? '#DC1C13'
                                : 'white',
                            color: '#fff',
                            borderRadius: '6px',
                            minWidth: 80,
                          }}
                          size="small"
                          label={ConvertirMille(category.fast_trank)}
                        />
                      </TableCell>

                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                          }}
                        >
                          {ConvertirMille(category.speciaux)} %
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          color="textSecondary"
                          sx={{
                            fontSize: 'h6.fontSize',
                          }}
                        >
                          {ConvertirMille(category.normaux)} %
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={6}
                      count={categories.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputprops: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default TempsSortieCategory;

TempsSortieCategory.propTypes = {
  datedebut: PropTypes.string.isRequired,
  datefin: PropTypes.string.isRequired,
  idResto: PropTypes.string.isRequired,
};
