import { useEffect, useState } from 'react'
import authService from '../../services/auth.service';
import { privateReqApiBackoffice, privateRequest } from '../../requestMethods';


const useEtablissements = () => {
    const currentUser=authService.getCurrentUser()
    const [etablissements, setEtablissements] = useState([])
    const retrieveEtablissements = () => {
      privateReqApiBackoffice.get(`/api/franchiseprocaisse/v2/restos/${currentUser?.id}`)
          .then(async(response) => {
            if(currentUser?.roles==="guest"){
              const res2=await privateRequest.get(`/api/v1/dashboard/findAllRestosGuest/${currentUser?.id_guest}`)
              const formatedResto=res2?.data?.map(guestR=>response.data?.find(franR=>franR?.restos?.pseudo===guestR?.pseudo))
              setEtablissements((formatedResto||[])?.filter(el=>el!==undefined));
            }else{
              setEtablissements(response.data || []);
            }
          })
          .catch(e => {
            console.log(e);
          });
      };
    
    
      useEffect(() => {
        if(currentUser?.id && currentUser?.roles!=="moderator"){
          retrieveEtablissements();
        }
      }, [currentUser?.id, currentUser?.roles]);

  return {etablissements:etablissements || []}
}

export default useEtablissements