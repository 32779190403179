import React, { useState } from 'react';
import { Button, Dialog, Grid, Stack, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Close } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { collection, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { setMessage } from '../../redux/alert/AlertSlice';
import { firestore } from '../../firebase/firebase';
import { storeLogs } from '../../utility/utilityFunctions';

const DeleteRelation = ({ onClose, restautId, cardId, setRestaurantCards, formatedCurrentUser, restoName }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  console.log('restautId =>', restautId, 'cardId =>', cardId);
  const handleDelete = async () => {
    setLoading(true);
    const db = firestore;
    const cardsRef = collection(db, 'RestosCartes');

    // Create a query with 'array-contains' to filter by 'restaurantIds'
    const cardQuery = query(
      cardsRef,
      where('restaurantIds', 'array-contains', restautId),
      where('cardId', '==', cardId),
    );

    // Execute the query to get the documents that match the criteria
    const cardQuerySnapshot = await getDocs(cardQuery);
    console.log("cardQuerySnapshot.docs[0]?.data()", cardQuerySnapshot.docs[0]?.data())
    try {
      if (!cardQuerySnapshot.empty) {
        const currentData = cardQuerySnapshot.docs[0]?.data();
        console.log('Document data:', currentData);

        const cardRef = doc(db, 'RestosCartes', cardQuerySnapshot.docs[0].id); // Get the document reference

        const updatedRestaurantIds = currentData.restaurantIds.filter((el) => el !== restautId);
        console.log('Updated restaurantIds:', updatedRestaurantIds);

        if (updatedRestaurantIds.length === 0) {
          // Delete the document if updatedRestaurantIds is empty
          await deleteDoc(cardRef);
          console.log('Document deleted as restaurantIds is empty.');
        } else {
          // Update the document if updatedRestaurantIds is not empty
          const timestamp = new Date();
          const updateDocument = {
            ...currentData,
            restaurantIds: updatedRestaurantIds,
            updatedAt: timestamp,
          };
          await updateDoc(cardRef, updateDocument); // Use updateDoc instead of setDoc
          console.log('Document updated successfully.');
        }

        // Update local state
        setRestaurantCards((prev) => ({
          ...prev,
          [restautId]: prev[restautId].filter((el) => el.id !== cardId),
        }));
                const details = {
                  resto: { siret: restautId, name:restoName },
                  deletedCarte: cardId,
                };
                await storeLogs(formatedCurrentUser, 'Générer Carte', 'Suppression', details);
              
        // Success message
        dispatch(
          setMessage({
            content: 'La suppression de la relation a été effectuée avec succès!',
            type: 'success',
          }),
        );
      } else {
        console.log('No such document!');
      }
    } catch (error) {
      console.error('Erreur lors de la suppression de la relation :', error);
      dispatch(
        setMessage({
          content: 'Une erreur est survenue lors de la suppression.',
          type: 'error',
        }),
      );
    }
    setLoading(false);
    onClose();
  };

  return (
    <Dialog open onClose={onClose} fullWidth>
      <Stack p={3}>
        <Grid spacing={2} container>
          <Grid dispaly="flex" textAlign="right" item xs={12}>
            <Close sx={{ cursor: 'pointer' }} onClick={onClose} color="#ff7b00" />
          </Grid>
          <Grid item xs={12}>
            <Typography>Êtes-vous sûr de vouloir supprimer cette Relation ?</Typography>
          </Grid>
          <Grid spacing={2} display="flex" alignItems="center" gap="8px" item xs={12}>
            <Button onClick={onClose} variant="outlined">
              Annuler
            </Button>
            <Button
              disabled={loading}
              sx={{
                backgroundColor: (theme) => theme.palette.error.main,
              }}
              onClick={handleDelete}
              variant="contained"
            >
              Supprimer
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Dialog>
  );
};
DeleteRelation.propTypes = {
  onClose: PropTypes.any.isRequired,
  restautId: PropTypes.any.isRequired,
  cardId: PropTypes.any.isRequired,
  setRestaurantCards: PropTypes.any.isRequired,
  formatedCurrentUser: PropTypes.any.isRequired,
  restoName: PropTypes.any.isRequired,
};
export default DeleteRelation;
