import React , {useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import useCartes from './views/campagne/campagnes-hooks/useCartes';
import { handleLogout, markOffline, markOnline, updateUserActivity } from './utility/utilityFunctions';
import {
  getFranchiseInfo,
  getCarteFileStorage2,
  getListeLangues,
  getsizes,
  getPrinters,
} from './redux/apiCalls';
import authService from './services/auth.service';


const App2 = () => {
    localStorage?.setItem('guideStatus', 'removed');
  localStorage?.removeItem('removeNotification2');
  const currentUser = authService.getCurrentUser();
  const { currentUserInfo } = useSelector((state) => state.currentUserInfo);
  
  const { config } = useSelector((state) => state.carte);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { sizes3, loading } = useCartes();
  useEffect(() => {
    if (
      [
        '/campagne/MesCartes',
        '/campagne/MesProduits',
        '/campagne/DeploiementCarte',
        '/config/mesTailles'
      ].includes(pathname)
    ) {
      if (currentUser?.roles === 'moderator' && currentUserInfo?.fk_franchise) {
        getsizes(currentUserInfo?.fk_franchise?.toString(), dispatch, []);
      } else if (currentUser?.id && currentUser?.roles !== 'moderator' && !loading && sizes3) {
        getsizes(currentUser?.id, dispatch, sizes3);
      }
    }
  }, [currentUser?.id, currentUserInfo?.fk_franchise, sizes3?.length, loading, pathname]);

  useEffect(() => {
    if (
      currentUser?.id &&
      [
        '/campagne/MesCartes',
        '/campagne/MesProduits',
        '/campagne/DeploiementCarte',
        '/config/mesImprimantes',
      ].includes(pathname)
    ) {
      getPrinters(currentUser?.roles==="moderator" ?  currentUserInfo?.fk_franchise?.toString() :currentUser?.id, dispatch);
    }
  }, [currentUser?.id, pathname, currentUserInfo?.fk_franchise]);

  const FranchiseLangues =
    currentUserInfo?.countryFranchiseInfo?.languages &&
    currentUserInfo?.countryFranchiseInfo?.languages.length !== 0
      ? currentUserInfo?.countryFranchiseInfo?.languages
      : [
          {
            code: 'fr',
            label: 'Français',
            id: 1,
            country_languages: {
              is_default: true,
            },
          },
        ];
  useEffect(() => {
    if (currentUser) {
      getFranchiseInfo(dispatch, currentUser);
    }
  }, [currentUser?.id, pathname]);
  useEffect(() => {
    
  }, [currentUser?.id, ]);
  useEffect(() => {
    if (
      currentUser?.id &&
      currentUserInfo?.id &&
      [
        '/campagne/MesCartes',
        '/campagne/MesProduits',
        '/campagne/DeploiementCarte',
        '/config/mesLangues',
      ].includes(pathname)
    ) {
      if (currentUserInfo && currentUser?.roles !== 'moderator') {
        getListeLangues(
          currentUser?.id,
          dispatch,
          FranchiseLangues,
          currentUserInfo?.countryFranchiseInfo?.name || 'non trouvé',
        );
      }
    }
  }, [currentUser?.id, pathname, currentUserInfo?.id, currentUserInfo?.countryFranchiseInfo?.name]);

  useEffect(() => {
    if (currentUser && config) {
      getCarteFileStorage2(config, currentUser?.roles!=="moderator" ? currentUser?.id : currentUserInfo?.fk_franchise, dispatch);
    }
  }, [config?.campagne, currentUser?.id, currentUserInfo?.fk_franchise]);
  const navigate = useNavigate();
  useEffect(async () => {
    if (
      currentUser?.id &&
      !currentUser?.superAdminLogin &&
      currentUserInfo?.isBlocked 
    ) {
      navigate('/accessDenied');
    }
   
  }, [currentUserInfo?.isBlocked, pathname, currentUser?.id, currentUser?.superAdminLogin]);
  // useEffect(async () => {
  //   if (
  //     currentUser?.id &&
  //     !currentUser?.superAdminLogin 
  //   ) {
  //     checkChangePwd=()=>
  //   }
   
  // }, [currentUserInfo?.isBlocked, pathname, currentUser?.id, currentUser?.superAdminLogin]);
  updateUserActivity(currentUser, pathname, null)


const userId = `${currentUser?.id}_${currentUser?.roles}${
  currentUser?.roles === 'guest' ? '_' + currentUser?.id_guest : ''
}`;
window.onbeforeunload = () => {
  
  markOffline(userId);
};

let timeout;
let isUserOnline = false;
const resetTimeout = () => {
  if(!isUserOnline && pathname!=="/auth/login"){
    markOnline(userId);
    isUserOnline = true;
  }
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    markOffline(userId);
    isUserOnline = false;
  }, 60000); 
};

window.addEventListener("mousemove", resetTimeout);
window.addEventListener("keypress", resetTimeout);
resetTimeout();
const checkPassWordChanged=async()=>{
  try {
    const body= {
      "role" : currentUser?.roles ,
      "id": Number(currentUser?.id) 
  }
  const token2=JSON?.parse(localStorage.getItem("user"))?.accessToken
  await axios.post(
    "https://api-super-admin.biborne.com/v2/app-biborne-manager/check-suspension",
    body, 
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token2}`
      }
    }
  );
  } catch (error5) {
    
    if(error5?.request?.status===403){
      handleLogout(dispatch)
    }
    console.log("error while checking check-suspension", error5)
  }
}
useEffect(() => {
  if(currentUser?.id && !currentUser?.superAdminLogin)checkPassWordChanged()
}, [pathname, currentUser?.id])

  return (
    <div/>
  )
}

export default App2