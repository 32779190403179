import AR from '../assets/images/flags/AR.png';
import FR from '../assets/images/flags/FR.png';
import EN from '../assets/images/flags/EN.png';
import SP from '../assets/images/flags/SP.png';
import TR from '../assets/images/flags/TR.png';

export const tags2 = [
  {
    number_tag: 1,
    deleted: false,
    background_catalog: 'background_1.jpg',
    logo_tag: 'logo_1.png',
    idTag: 1,
    name_tag: 'Tag 1',
    orderTypeDisponible: [
      { id: 3, name: 'Livraison' },
      { id: 1, name: 'Sur Place' },
    ],
  },
  {
    number_tag: 2,
    deleted: false,
    background_catalog: 'background_2.jpg',
    logo_tag: 'logo_2.png',
    idTag: 2,
    name_tag: 'Tag 2',
    orderTypeDisponible: [
      { id: 3, name: 'Livraison' },
    ],
  },
  {
    number_tag: 3,
    deleted: false,
    background_catalog: 'background_3.jpg',
    logo_tag: 'logo_3.png',
    idTag: 3,
    name_tag: 'Tag 3',
    orderTypeDisponible: [
      { id: 1, name: 'Sur Place' },
    ],
  },
  {
    number_tag: 4,
    deleted: false,
    background_catalog: 'background_4.jpg',
    logo_tag: 'logo_4.png',
    idTag: 4,
    name_tag: 'Tag 4',
    orderTypeDisponible: [
      { id: 1, name: 'Sur Place' },
      { id: 2, name: 'À Emporter' },
    ],
  },
  {
    number_tag: 5,
    deleted: false,
    background_catalog: 'background_5.jpg',
    logo_tag: 'logo_5.png',
    idTag: 5,
    name_tag: 'Tag 5',
    orderTypeDisponible: [
      { id: 1, name: 'Sur Place' },
    ],
  },
];

export const screensList = [
  { idScreen: 1, number_screen: 1, name_screen: 'Ecran 1' },
  { idScreen: 2, number_screen: 2, name_screen: 'Ecran 2' },
  { idScreen: 3, number_screen: 3, name_screen: 'Ecran 3' },
  { idScreen: 4, number_screen: 4, name_screen: 'Ecran 4' },
  { idScreen: 5, number_screen: 5, name_screen: 'Ecran 5' },
  { idScreen: 6, number_screen: 6, name_screen: 'Ecran 6' },
  { idScreen: 7, number_screen: 7, name_screen: 'Ecran 7' },
  { idScreen: 8, number_screen: 8, name_screen: 'Ecran 8' },
  // { idScreen: 9, number_screen: 9, name_screen: 'Ecran 9' },
  // { idScreen: 10, number_screen: 10, name_screen: 'Ecran 10' },
  // { idScreen: 11, number_screen: 11, name_screen: 'Ecran 11' },
  // { idScreen: 12, number_screen: 12, name_screen: 'Ecran 12' },
  // { idScreen: 13, number_screen: 13, name_screen: 'Ecran 13' },
  // { idScreen: 14, number_screen: 14, name_screen: 'Ecran 14' },
  // { idScreen: 15, number_screen: 15, name_screen: 'Ecran 15' },
];
export const availaibleTypeOrder = [
  {
    name: 'Sur Place',
    numero: 1,
    schedule: [
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 0,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 1,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 2,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 3,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 4,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 5,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 6,
      },
    ],
  },
  {
    name: 'A emporté',
    numero: 2,
    schedule: [
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 0,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 1,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 2,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 3,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 4,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 5,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 6,
      },
    ],
  },
];
export const HoraireMarques = [
  {
    name: 'Burgers',
    numero: 1,
    schedule: [
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 0,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 1,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 2,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 3,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 4,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 5,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 6,
      },
    ],
  },
  {
    name: 'Pizza',
    numero: 2,
    schedule: [
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 0,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 1,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 2,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 3,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 4,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 5,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 6,
      },
    ],
  },
  {
    name: "Sweet Delights",
    numero: 3,
    schedule: [
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 0,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 1,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 2,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 3,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 4,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 5,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 6,
      },
    ],
  },
];
export const printers = [
  {
    idPrinter: 2,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: 'imprimante chamas 1',
    number_ticket: 1,
    ip_address: '192.168.1.201',
    type: 'Réseau',
  },
  {
    idPrinter: 3,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 4,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 5,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: 'imprimante chamas 2',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 6,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 7,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 8,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 9,
    width_ticket: 48,
    type_printer: 'kitchen',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 10,
    width_ticket: 48,
    type_printer: 'label',
    name: 'imprimante label',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 11,
    width_ticket: 48,
    type_printer: 'label',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 12,
    width_ticket: 48,
    type_printer: 'label',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
  {
    idPrinter: 13,
    width_ticket: 48,
    type_printer: 'label',
    name: '0',
    number_ticket: 1,
    ip_address: '192.168.1.211',
    type: 'Réseau',
  },
];
export const historiqueData = [
  // Mes Cartes
  // 1) Creation
  {
    createdAt: 'Oct 7, 2011 - 13:32:22',
    id: 1,
    module: 'Mes Cartes',
    action: 'Ajout',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    user_type: 'admin', // roles
    source: 'super-admin', // "login"
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    details: {
      carteName: 'Carte 1.json',
    },
  },
  {
    module: 'Mes Produits',
    action: 'Modification',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    source: 'super-admin',
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    details: {
      carteName: 'Carte 1.json',
      elementType: 'option',
      elementName: 'Ketchup',
      categoryParent: 'Sauces ',
      changes: {
        'prix tarif 1': {
          oldValue: 23.3,
          newValue: 24,
        },
        'prix tarif 2': {
          oldValue: 23.3,
          newValue: 24,
        },
        'prix tarif 3': {
          oldValue: 23.3,
          newValue: 24,
        },
      },
    },
  },
  // 2) Modification
  {
    createdAt: 'Oct 7, 2011 - 13:32:22',
    id: 2,
    module: 'Mes Cartes',
    action: 'Modification',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    user_type: 'admin', // roles
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    source: 'super-admin', // peut etres null
    details: {
      carteName: 'Carte 1.json',
      changes: {
        description: {
          oldValue: '',
          newValue: 'description added...',
        },
        nom: {
          oldValue: 'Carte 1.json',
          newValue: 'Carte 1 (updated).json',
        },
      },
    },
  },
  // 3) Suppression
  {
    module: 'Mes Cartes',
    action: 'Suppression',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    user_type: 'admin', // roles
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    source: 'super-admin', // peut etres null
    details: {
      carteName: 'Carte 1.json',
    },
  },
  // 4) Duplication
  {
    module: 'Mes Cartes',
    action: 'Duplication',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    user_type: 'admin', // roles
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    source: 'super-admin', // peut etres null
    details: {
      carteOrigine: 'Carte 1.json',
      CopiedCarte: 'Carte 1 copie.json',
    },
  },
  // Mes Produits
  // 1) Suppression
  {
    module: 'Mes Produits',
    action: 'Suppression',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    source: 'super-admin',
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    details: {
      carteName: 'Carte 1.json',
      elementType: 'categorie', // produit, group option, option, ingrendient, famille, famille option, promotion
      elementName: 'Burger',
      categoryParent: 'Burgers', // en cas de categorie parent il sera null
    },
  },
  // 2) Modification de prix
  {
    module: 'Mes Produits',
    action: 'Modification',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    source: 'super-admin',
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    details: {
      carteName: 'Carte 1.json',
      elementType: 'produit',
      elementName: 'Menu Duo Tacos😁',
      categoryParent: 'Nos Menus Duo',
      categoryParent2: 'Nos Menu', // en cas de produit a l'interieur d'une categorie et cette categorie est a l'interieur d'une autre categorie
      changes: {
        'prix tarif 1': {
          oldValue: 23.3,
          newValue: 24,
        },
      },
    },
  },
  {
    module: 'Mes Produits',
    action: 'Modification',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    source: 'super-admin',
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    details: {
      carteName: 'Carte 1.json',
      elementType: 'produit',
      elementName: 'Burger',
      categoryParent: 'Burgers',
      changes: {
        'prix Sur Place junior tarif 1': {
          oldValue: 23.3,
          newValue: 24,
        },
        'prix Sur Place junior tarif 2': {
          oldValue: 23.3,
          newValue: 24,
        },
        'prix Sur Place junior tarif 3': {
          oldValue: 23.3,
          newValue: 24,
        },
        'prix Sur Place mega tarif 1': {
          oldValue: 23.3,
          newValue: 24,
        },
        'prix Sur Place mega tarif 2': {
          oldValue: 23.3,
          newValue: 24,
        },
        'prix Sur Place mega tarif 3': {
          oldValue: 23.3,
          newValue: 24,
        },
      },
    },
  },
  // 3) Ajout d’option pour un produit
  {
    module: 'Mes Produits',
    action: 'Modification',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    source: 'super-admin',
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    details: {
      carteName: 'Carte 1.json',
      elementType: 'produit',
      elementName: 'Burger',
      categoryParent: 'Burgers',
      changes: {
        'options ajoutés': {
          'taille 7up': {
            has_limit_options: true,
            min_option: 1,
            max_option: 2,
          },
          "Ch'dips menu": {
            has_limit_options: false,
          },
        },
        'Boissons max_option': {
          oldValue: 2,
          newValue: 3,
        },
      },
    },
  },
  // 4) Modification d'option
  {
    module: 'Mes Produits',
    action: 'Modification',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    source: 'super-admin',
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    details: {
      carteName: 'Carte 1.json',
      elementType: 'produit',
      elementName: 'Burger',
      categoryParent: 'Burgers',
      changes: {
        'taille 7up min_option': {
          oldValue: 1,
          newValue: 2,
        },
        'taille 7up max_option': {
          oldValue: 2,
          newValue: 3,
        },
      },
    },
  },
  // 5) Désactivation
  {
    module: 'Mes Produits',
    action: 'Activation',
    details: {
      carteName: 'Carte 1.json',
      elementType: 'categorie', // produit, group option, option, ingrendient, famille, famille option, promotion
      elementName: 'Burger',
      categoryParent: 'Burgers', // en cas de sous category en cas des category parent il sera null
    },
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    source: 'super-admin',
  },
  // 6) Duplication
  {
    module: 'Mes Produits',
    action: 'Duplication',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    source: 'super-admin',
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    details: {
      carteName: 'Carte 1.json',
      elementType: 'categorie', // produit, group option, option, ingrendient, famille, famille option, promotion
      elementName: 'Burger',
      categoryParent: 'Burgers', // en cas de sous category en cas des category parent il sera null
    },
  },
  // 7) Ajout
  {
    module: 'Mes Produits',
    action: 'Ajout',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    source: 'super-admin',
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    details: {
      carteName: 'Carte 1.json',
      elementType: 'categorie', // produit, group option, option, ingrendient, famille, famille option, promotion
      elementName: 'Burger',
      categoryParent: 'Burgers', // en cas de sous category en cas des category parent il sera null
    },
  },
  // Générer carte
  // 1) Ajout
  {
    module: 'Générer carte',
    action: 'Ajout',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    source: 'super-admin',
    details: {
      resto: {
        cartes: ['Carte 1.json', 'Carte 2.json'],
        siret: '212112211212',
        name: 'dunkin donuts paris',
      },
      typeDeploiment: 'illimité',
    },
  },
  // 2) Suppression de carte
  {
    module: 'Générer carte',
    action: 'Suppression',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    source: 'super-admin',
    details: {
      resto: { cartes: ['Carte 1.json'], siret: '212112211212', name: 'dunkin donuts paris' },
    },
  },
  // 3) Génération de la carte
  {
    module: 'Générer carte',
    action: 'Génération',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    source: 'super-admin',
    details: {
      restosGeneres: [
        {
          siret: '12345678910',
          name: 'Food journey',
          cartes: ['Carte 1.json', 'Carte 2.json'],
        },
        {
          siret: '212112211212',
          name: 'dunkin donuts paris',
          cartes: ['Carte 1.json'],
        },
      ],
    },
  },
  // 4) Personalisation de la carte
  {
    module: 'Générer carte',
    action: 'Personalisation',
    franchise_id: '49',
    user_name: 'Laila Ouhamou',
    resto_id: null,
    email: 'l.ouhamou@biborne.com',
    source: 'super-admin',
    details: {
      resto: {
        siret: '212112211212',
        name: 'dunkin donuts paris',
        cartes: ['Carte 1.json', 'Carte 2.json'],
      },
      changes: {
        Produits: [
          {
            name: 'Doublecheese bacon',
            category: 'Burgers à la carte',
            changes: {
              'prix Sur Place tarif 1': {
                oldValue: 5.5,
                newValue: 6.5,
              },
              'prix A Emporter tarif 1': {
                oldValue: 5.5,
                newValue: 6.5,
              },
            },
          },
          {
            name: 'Wrap Tenders',
            category: 'Burgers à la carte',
            changes: {
              'Rupture de stock': {
                oldValue: false,
                newValue: true,
              },
            },
          },
        ],
        Categories: [
          {
            name: 'Offre cinéma',
            changes: {
              Status: {
                oldValue: true,
                newValue: false,
              },
            },
          },
        ],
        Options: [
          {
            name: 'Avec cacahuetes',
            category: 'Cacahuetes',
            changes: {
              'prix tarif 1': {
                oldValue: 0.2,
                newValue: 0.3,
              },
            },
          },
        ],
        GroupOption: [
          {
            name: 'Sauces',
            changes: {
              Status: {
                oldValue: false,
                newValue: true,
              },
            },
          },
        ],
        Famille: [
          {
            name: 'Menu Chicken Beef',
            category: 'Burgers',
            changes: {
              prix: {
                oldValue: 0,
                newValue: 2.4,
              },
            },
          },
        ],
        GroupFamille: [
          {
            name: 'Boissons',
            changes: {
              Status: {
                oldValue: true,
                newValue: false,
              },
            },
          },
        ],
      },
    },
  },
];

export const tags = [
  {
    number_tag: 1,
    deleted: false,
    background_catalog: 'backgound_1.jpg',
    logo_tag: 'logo_1.png',
    idTag: 1,
    name_tag: 'tag1',
    schedule: [
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 0,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 1,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 2,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 3,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 4,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 5,
      },
      {
        time_periods: [
          {
            start: '00:00',
            end: '23:59',
            id: 1,
          },
        ],
        day_of_week: 6,
      },
    ],
    orderTypeDisponible: [
      { id: 3, name: 'Livraison' },

      { id: 1, name: 'Sur Place' },
    ],
  },
  {
    number_tag: 2,
    deleted: false,
    background_catalog: 'backgound_2.jpg',
    logo_tag: 'logo_2.png',
    idTag: 2,
    name_tag: 'tag2',
  },
  {
    number_tag: 3,
    deleted: false,
    background_catalog: 'backgound_3.jpg',
    logo_tag: 'logo_3.png',
    idTag: 3,
    name_tag: 'tag3',
  },
  {
    number_tag: 4,
    deleted: false,
    background_catalog: 'backgound_4.jpg',
    logo_tag: 'logo_4.png',
    idTag: 4,
    name_tag: 'tag4',
  },
  {
    number_tag: 5,
    deleted: false,
    background_catalog: 'backgound_5.jpg',
    logo_tag: 'logo_5.png',
    idTag: 5,
    name_tag: 'tag5',
  },
  {
    number_tag: 6,
    deleted: false,
    background_catalog: 'backgound_6.jpg',
    logo_tag: 'logo_6.png',
    idTag: 6,
    name_tag: 'tag6',
  },
];
export const tags3 = [
  {
    number_tag: 1,

    deleted: false,

    background_catalog: 'background_1.jpg',

    logo_tag: 'logo_1.png',

    idTag: 1,

    name_tag: 'Tag 1',

    orderTypeDisponible: [
      { id: 3, name: 'Livraison' },

      { id: 1, name: 'Sur Place' },
    ],
  },

  {
    number_tag: 2,

    deleted: false,

    background_catalog: 'background_2.jpg',

    logo_tag: 'logo_2.png',

    idTag: 2,

    name_tag: 'Tag 2',

    orderTypeDisponible: [{ id: 3, name: 'Livraison' }],
  },

  {
    number_tag: 3,

    deleted: false,

    background_catalog: 'background_3.jpg',

    logo_tag: 'logo_3.png',

    idTag: 3,

    name_tag: 'Tag 3',

    orderTypeDisponible: [{ id: 1, name: 'Sur Place' }],
  },

  {
    number_tag: 4,

    deleted: false,

    background_catalog: 'background_4.jpg',

    logo_tag: 'logo_4.png',

    idTag: 4,

    name_tag: 'Tag 4',

    orderTypeDisponible: [
      { id: 1, name: 'Sur Place' },

      { id: 2, name: 'À Emporter' },
    ],
  },

  {
    number_tag: 5,

    deleted: false,

    background_catalog: 'background_5.jpg',

    logo_tag: 'logo_5.png',

    idTag: 5,

    name_tag: 'Tag 5',

    orderTypeDisponible: [{ id: 1, name: 'Sur Place' }],
  },
];
export const currencyCodeToLocale = {
  USD: 'en-US',
  MAD: 'fr-Fr',
  EUR: 'fr-FR',
  GBP: 'en-GB',
  JPY: 'ja-JP',
  AUD: 'en-AU',
  CNY: 'zh-CN',
  INR: 'en-IN',
  CHF: 'de-CH',
  AED: 'ar-AE',
  MXN: 'es-MX',
  BRL: 'pt-BR',
  ZAR: 'en-ZA',
  SGD: 'en-SG',
  HKD: 'en-HK',
  RUB: 'ru-RU',
  SAR: 'ar-SA',
  SEK: 'sv-SE',
  NZD: 'en-NZ',
  NOK: 'no-NO',
  DKK: 'da-DK',
  KRW: 'ko-KR',
  THB: 'th-TH',
  TRY: 'tr-TR',
  MYR: 'en-MY',
  IDR: 'id-ID',
  PHP: 'en-PH',
  CAD: 'en-CA',
  ARS: 'es-AR',
  CLP: 'es-CL',
  COP: 'es-CO',
  VND: 'vi-VN',
  EGJ: 'ar-EG',
  IQD: 'ar-IQ',
  ILS: 'he-IL',
  JOD: 'ar-JO',
  KWD: 'ar-KW',
  LBP: 'ar-LB',
  OMR: 'ar-OM',
  QAR: 'ar-QA',
  TND: 'ar-TN',
  MOP: 'zh-MO',
  BHD: 'ar-BH',
  NGN: 'en-NG',
  PKR: 'en-PK',
  AOA: 'pt-AO',
  BGN: 'bg-BG',
  HRK: 'hr-HR',
  CZK: 'cs-CZ',
  HUF: 'hu-HU',
  PLN: 'pl-PL',
  RON: 'ro-RO',
  RSD: 'sr-RS',
  LKR: 'si-LK',
  UAH: 'uk-UA',
  BDT: 'bn-BD',
  AMD: 'hy-AM',
  GEL: 'ka-GE',
  AZN: 'az-AZ',
  KZT: 'kk-KZ',
  UZS: 'uz-UZ',
  KGS: 'ky-KG',
  TJS: 'tg-TJ',
  BYN: 'be-BY',
  MNT: 'mn-MN',
  KHR: 'km-KH',
  LAK: 'lo-LA',
  MMK: 'my-MM',
  NIO: 'es-NI',
  CRC: 'es-CR',
  PAB: 'es-PA',
  PEN: 'es-PE',
  DOP: 'es-DO',
  TWD: 'zh-TW',
  IRR: 'fa-IR',
  TMT: 'tk-TM',
  YER: 'ar-YE',
  UYU: 'es-UY',
  PYG: 'es-PY',
  BOB: 'es-BO',
  VEF: 'es-VE',
  HNL: 'es-HN',
  GTQ: 'es-GT',
  SVC: 'es-SV',
  BZD: 'en-BZ',
  BMD: 'en-BM',
  XPF: 'fr-PF',
  FJD: 'en-FJ',
  PGK: 'en-PG',
  STD: 'pt-ST',
};
export const timeTable = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];
export const timeZoneMap = [
  {
    code: 'CA',
    countryName: 'Canada',
    timeZone: 'America/Toronto',
    GMTOfsset: 'UTC -04:00',
  },
  {
    code: 'MA',
    countryName: 'Morocco',
    timeZone: 'Africa/Casablanca',
    GMTOfsset: 'UTC +01:00',
  },
  {
    code: 'NC',
    countryName: 'New Caledonia',
    timeZone: 'Pacific/Noumea',
    GMTOfsset: 'UTC +11:00',
  },
  {
    code: 'FR',
    countryName: 'France',
    timeZone: 'Europe/Paris',
    GMTOfsset: 'UTC +02:00',
  },
  {
    code: 'SA',
    countryName: 'Saudi Arabia',
    timeZone: 'Asia/Riyadh',
    GMTOfsset: 'UTC +03:00',
  },
  {
    code: 'PF',
    countryName: 'French Polynesia',
    timeZone: 'Pacific/Gambier',
    GMTOfsset: 'UTC -09:00',
  },
  {
    code: 'CH',
    countryName: 'Switzerland',
    timeZone: 'Europe/Zurich',
    GMTOfsset: 'UTC +02:00',
  },
];

export const headTable = [
  {
    id: 1,
    label: 'name',
    title: 'Client',
  },
  {
    id: 2,
    label: 'lastOrder',
    title: 'Dernière commande ',
  },
  {
    id: 3,
    label: 'nbOrders',
    title: "Nombre d'ordres",
  },
  {
    id: 5,
    label: 'orderTypes',
    title: "Types d'ordres",
  },
  {
    id: 6,
    label: 'orderSources',
    title: "Sources d'ordre",
  },
];

export const displayFlag = (codeLangue) => {
  let img;
  switch (codeLangue) {
    case 'ar':
      img = AR;
      break;
    case 'en':
      img = EN;
      break;
    case 'es':
      img = SP;
      break;
    case 'fr':
      img = FR;
      break;
    case 'tr':
      img = TR;
      break;
    default:
      img = null;
      break;
  }
  return img;
};
export const orderType=[
  {
    name:'Sur Place',
    id:1
  },
  {
    name:'A Emporter',
    id:2
  },
]
export const orderPriority = {
  'Sur Place': 1,
  'A Emporter': 2,
  'En Livraison': 3,
  Platform: 4,
  Drive: 5,
};
export const orderSizesPriority = {
  junior: 1,
  senior: 2,
  mega: 3,
  size1: 4,
  size2: 5,
  size3: 6,
  size4: 7,
  size5: 8,
  size6: 9,
  size7: 10,
};

export const typeCommande = [
  {
    type_order: 'Sur Place',
    tariff: 1,
  },
  {
    type_order: 'A Emporter',
    tariff: 1,
  },
  {
    type_order: 'En Livraison',
    tariff: 1,
  },
  {
    type_order: 'Platform',
    tariff: 1,
  },
  {
    type_order: 'Drive',
    tariff: 1,
  },
  {
    type_order: 'Sur Place',
    tariff: 2,
  },
  {
    type_order: 'A Emporter',
    tariff: 2,
  },
  {
    type_order: 'En Livraison',
    tariff: 2,
  },
  {
    type_order: 'Platform',
    tariff: 2,
  },
  {
    type_order: 'Drive',
    tariff: 2,
  },
  {
    type_order: 'Sur Place',
    tariff: 3,
  },
  {
    type_order: 'A Emporter',
    tariff: 3,
  },
  {
    type_order: 'En Livraison',
    tariff: 3,
  },
  {
    type_order: 'Platform',
    tariff: 3,
  },
  {
    type_order: 'Drive',
    tariff: 3,
  },
];
export const productKeysInfrensh = {
  display_image_in_kitchen: "Afficher l'image en cuisine",
  alias_product: 'Alias du produit',
  available_caisse: 'Disponible en caisse',
  price: 'Prix',
  hide_price_in_borne: 'Masquer le prix sur la borne',
  best_sale: 'Meilleure vente',
  product_loyalty: 'Produit fidélité',
  price_point: 'Point de prix',
  available_takeaway: 'Disponible à emporter',
  marque: 'Marque',
  hidden_ubereat: 'Caché sur Uber Eats',
  hidden_caisse: 'Caché en caisse',
  idCat: 'ID de catégorie',
  free_price: 'Prix libre',
  additional_sale_atSpot: 'Vente additionnelle sur place',
  product_ubereat: 'Produit Uber Eats',
  hidden_online: 'Caché en ligne',
  moment_product: 'Produit du moment',
  available_on_the_spot: 'Disponible sur place',
  additional_sale_take_away: 'Vente additionnelle à emporter',
  unavailable_online: 'Indisponible en ligne',
  top_product: 'Produit phare',
  valid_caisse: 'Valide en caisse',
  later: 'Plus tard',
  promo_basket: 'Panier promotionnel',
  sold_out: 'Épuisé',
  additional_sale: 'Vente additionnelle',
  many_size: 'Plusieurs tailles',
  unit: 'Unité',
  hidden_borne: 'Caché sur la borne',
  hide_name_in_borne: 'Masquer le nom sur la borne',
  daily_stock: 'Stock journalier',
  hidden: 'Caché',
  enable_in_opening_mode: 'Activer en mode ouverture',
  different_price: 'Prix différent',
};

export const modules = [
  {
    name: 'Mes Cartes',
    color: '#A854F9',
    backgroundColor: '#a854f91a',
  },
  {
    name: 'Mes Produits',
    color: '#7CB6FF',
    backgroundColor: 'rgba(124, 182, 255, 0.15)',
  },
  {
    name: 'Générer Carte',
    color: '#F5771A',
    backgroundColor: 'rgba(245, 119, 26, 0.15)',
  },
];
