import { v4 as uuidv4 } from 'uuid';
import { doc, getDoc, getDocs, query, collection, where, setDoc } from 'firebase/firestore';
import axios from 'axios';
import {
  setStorageFileFailure,
  setStorageFileStart,
  setStorageFileSucess,
} from './carte/carteSlice';
import { firestore, storage } from '../firebase/firebase';
import {  setLoadingSizes, setSizes } from './config/TailleSlice';
import { handleLogout, updateCart } from '../utility/utilityFunctions';
import { setCurrentUserInfo } from './currentUser/currentUserInfoSlice';
import FranchiseService from '../services/FranchiseService';
import { setlisteLangues } from './config/listeLanguesSlice';
import { privateReqApiBackoffice, privateRequest } from '../requestMethods';
import { setLoadingPrinters, setPrinters } from './config/printersSlice';
// import axios from 'axios';
// import { printers } from '../data/data';

const db = firestore; // Récupérer une référence à Firestore

export const getCarteFileStorage2 = (config, currentUserId, dispatch) => {
  if (!currentUserId) {
    // Explicitly return undefined when currentUserId is falsy
    return undefined;
  }

  if (dispatch) dispatch(setStorageFileStart());

  return new Promise((resolve, reject) => {
    const filePath = `CartesCampagnes/${currentUserId}/${config.campagne}`;
    const fileRef = storage.ref(filePath);

    fileRef
      .getDownloadURL()
      .then((url) => {
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            resolve(data); // Resolve the promise with the fetched data
            if (dispatch) dispatch(setStorageFileSucess(data));
          })
          .catch((error) => {
            if (dispatch) dispatch(setStorageFileFailure());
            reject(error); // Reject the promise in case of fetch failure
          });
      })
      .catch((error) => {
        console.error('Erreur lors de la récupération du fichier JSON', error);

        // Handle error messaging
        let errorMessage = "Erreur lors de la récupération du fichier JSON";
        switch (error?.code) {
          case 'storage/unknown':
            errorMessage = "Une erreur inconnue s'est produite.";
            break;
          case 'storage/object-not-found':
            errorMessage = "L'objet demandé n'a pas été trouvé.";
            break;
          case 'storage/bucket-not-found':
            errorMessage = "Le compartiment spécifié n'a pas été trouvé.";
            break;
          case 'storage/project-not-found':
            errorMessage = "Le projet spécifié n'a pas été trouvé.";
            break;
          case 'storage/quota-exceeded':
            errorMessage = "Le quota de stockage a été dépassé.";
            break;
          case 'storage/unauthenticated':
            errorMessage = "L'utilisateur n'est pas authentifié et n'est donc pas autorisé à effectuer l'action demandée.";
            break;
          case 'storage/unauthorized':
            errorMessage = "L'utilisateur n'est pas autorisé à effectuer l'action demandée.";
            break;
          case 'storage/retry-limit-exceeded':
            errorMessage = "Le nombre maximal de tentatives de récupération a été dépassé.";
            break;
          case 'storage/non-matching-checksum':
            errorMessage = "Le téléchargement a échoué car le contrôle de somme de contrôle ne correspondait pas.";
            break;
          case 'storage/canceled':
            errorMessage = "L'opération a été annulée par l'utilisateur.";
            break;
          case 'storage/invalid-checksum':
            errorMessage = "Le fichier téléchargé a une somme de contrôle invalide.";
            break;
          case 'storage/invalid-event-name':
            errorMessage = "Le nom de l'événement spécifié est invalide.";
            break;
          case 'storage/invalid-url':
            errorMessage = "L'URL spécifiée n'est pas valide.";
            break;
          case 'storage/invalid-argument':
            errorMessage = "Un ou plusieurs arguments fournis sont invalides.";
            break;
          case 'storage/no-default-bucket':
            errorMessage = "Aucun compartiment par défaut n'est configuré.";
            break;
          default:
            break;
        }

        if (dispatch) dispatch(setStorageFileFailure(errorMessage));
        reject(error); // Reject the promise in case of storage failure
      });
  });
};

export const getsizes=async(currentUserId, dispatch, campagnesizes, updateSizes)=>{
  dispatch(setLoadingSizes(true))
  const franchiseSizes= campagnesizes?.length!==0  ? campagnesizes?.map((css, index)=>({
    ...css,
    label_size:css.label_size || `Taille ${index+1}`
  })) : [
    {
      label_size:"junior",
      id_size: 1,
      name_size: 'junior',
      description: "",
      size_displayed: true,
    },
    {
      label_size:"senior",
      id_size: 2,
      name_size: 'senior',
      description: "",
      size_displayed: true,
    },
    {
      label_size:"mega",
      id_size: 3,
      name_size: 'mega',
      description: "",
      size_displayed: true,
    },
    {
      label_size:"size1",
      id_size: 4,
      name_size: 'size1',
      description: "",
      size_displayed: true,
    },
    {
      label_size:"size2",
      id_size: 5,
      name_size: 'size2',
      description: "",
      size_displayed: true,
    },
    {
      label_size:"size3",
      id_size: 6,
      name_size: 'size3',
      description: "",
      size_displayed: true,
    },
    {
      label_size:"size4",
      id_size: 7,
      name_size: 'size4',
      description: "",
      size_displayed: true,
    },
    {
      label_size:"size5",
      id_size: 8,
      name_size: 'size5',
      description: "",
      size_displayed: true,
    },
    {
      label_size:"size6",
      id_size: 9,
      name_size: 'size6',
      description: "",
      size_displayed: true,
    },
    {
      label_size:"size7",
      id_size: 10,
      name_size: 'size7',
      description: "",
      size_displayed: true,
    },
  ]
  const docRef = doc(db, 'listeSizes', currentUserId);
  const docSnap = await getDoc(docRef); 
  if (docSnap.exists()){
    if(updateSizes){
      console.log("delete the file and create newOne using newCampagnes sizes",updateSizes?.newCampagnesSizes)
      db.collection('listeSizes').doc(currentUserId).set({ listeSizes: updateSizes?.newCampagnesSizes});
      dispatch(setSizes(franchiseSizes));
    }else{
      
      const firestoreSizes = docSnap.data().listeSizes;
    dispatch(setSizes(firestoreSizes));
    }
    
  }else{
    try {
      // Create the collection
      db.collection('listeSizes').doc(currentUserId).set({ listeSizes: franchiseSizes});
      dispatch(setSizes(franchiseSizes)); // Assuming you want to set default language to FranchiseLangues
    } catch (error) {
      console.error('Error creating document:', error);
      // Handle error
    }
  }

  dispatch(setLoadingSizes(false))
}
export const getPrinters=async(currentUserId, dispatch)=>{
  dispatch(setLoadingPrinters(true))
  
  const docRef = doc(db, 'listePrinters', `${currentUserId}`);
  const docSnap = await getDoc(docRef); 
  if (docSnap.exists()){
    const firestorePrinters = docSnap.data().listePrinters;
    dispatch(setPrinters(firestorePrinters));
  }else{
    try {
      // Create the collection
      const printersK = Array.from({ length: 8 }, (_, index) => ({
        idPrinter: index + 2, 
        width_ticket: 48,
        type_printer: 'kitchen',
        name: 'imprimante preparation ' + (index + 1),
        number_ticket: 1,
        ip_address: '192.168.1.211',
        type: 'Réseau',
      }));
      const printersL = Array.from({ length: 4 }, (_, index) => ({
        idPrinter: index + 10, 
        width_ticket: 48,
        type_printer: 'label',
        name: 'imprimante etiquette ' + (index + 1),
        number_ticket: 1,
        ip_address: '192.168.1.211',
        type: 'Réseau',
      }));
      db.collection('listePrinters').doc(currentUserId).set({ listePrinters: [...printersK, ...printersL]});
      dispatch(setPrinters([...printersK, ...printersL])); 
    } catch (error) {
      console.error('Error creating document:', error);
      // Handle error
    }
  }

  dispatch(setLoadingPrinters(false))
}

export const getListeLangues = async (currentUserId, dispatch, FranchiseLangues, country) => {
 
  const docRef = doc(db, 'listesLangue', currentUserId);
  const docSnap = await getDoc(docRef);
  // Update the collection with the new array ListeLangues

  const ListeLangues = FranchiseLangues.map((fl) => {
    const formattedObjet = {
      id: fl.id,
      isDefault: fl.country_languages.is_default,
      label: fl.label,
      code: fl.code,
    };

    if (fl.country_languages.is_default) {
      return {
        ...formattedObjet,
        isActive: true,
      };
    }
    return {
      ...formattedObjet,
      isActive: false,
    };
  });

  if (docSnap.exists()) {
    if (docSnap.data() && docSnap?.data()?.country) {
      if(country===docSnap?.data()?.country){
        if(ListeLangues?.filter(el=>!docSnap?.data()?.listesLangue?.map(el2=>el2?.code)?.includes(el?.code))?.length!==0 || docSnap?.data()?.listesLangue?.filter(el=>!ListeLangues.map(el2=>el2?.code)?.includes(el?.code))?.length!==0){
          const updatedLanguagesList=ListeLangues?.map(el3=>{
            if(docSnap?.data()?.listesLangue?.map(el2=>el2?.code)?.includes(el3?.code)){
              return {
                ...el3,
                isActive:docSnap?.data()?.listesLangue?.find(el4=>el3?.code===el4?.code)?.isActive
              }
            }
            return el3
          })
          await setDoc(docRef, { listesLangue: updatedLanguagesList, country:country });
        dispatch(setlisteLangues(updatedLanguagesList));
        }else if(ListeLangues?.find(lan=>lan?.isDefault)?.code===docSnap?.data()?.listesLangue?.find(lan=>lan?.isDefault)?.code){
           dispatch(setlisteLangues(docSnap.data().listesLangue));  
          }else{
            const updatedLanguagesList=docSnap.data().listesLangue?.map(el5=>{
              if(el5?.code===ListeLangues?.find(lan=>lan?.isDefault)?.code){
               return {
                ...el5,
                isDefault:true,
                isActive:true
               }
              }
              return {
                ...el5,
                isDefault:false
              }
            })
            await setDoc(docRef, { listesLangue: updatedLanguagesList, country:country });
        dispatch(setlisteLangues(updatedLanguagesList));
          }
         
        
        
        
      }else{
        // recupere from superadmin
        await setDoc(docRef, { listesLangue: ListeLangues, country:country });
        dispatch(setlisteLangues(ListeLangues));
      }
    } else {
      dispatch(setlisteLangues(ListeLangues));
    }
  } else {
    try {
      // Create the collection
      db.collection('listesLangue').doc(currentUserId).set({ listesLangue: ListeLangues, country:country });
      dispatch(setlisteLangues(ListeLangues)); // Assuming you want to set default language to FranchiseLangues
    } catch (error) {
      console.error('Error creating document:', error);
      // Handle error
    }
  }
};
export const integrateUuIdforAllCampagne = async (currentUser, campagneUrl, dispatch) => {
  // integrate for one campgane
  return new Promise((resolve, reject) => {
    const filePath = `CartesCampagnes/${currentUser}/${campagneUrl}`;
    const fileRef = storage.ref(filePath);

    // Récupérer l'URL de téléchargement du fichier
    fileRef
      .getDownloadURL()
      .then((url) => {
        // Effectuer une requête GET pour récupérer les données JSON
        fetch(url)
          .then((response) => response.json())
          .then(async (data) => {
            // Résoudre la promesse avec les données récupérées
            resolve(data);
            try {
              const categoriesWithIdGlobal = data?.orderJson.categories.map((el) => {
                return {
                  ...el,
                  idGlobal: el.idGlobal ? el.idGlobal : uuidv4(),
                };
              });

              const updatedParentIdInCats = categoriesWithIdGlobal?.map((el) => {
                if (el.parent_id !== -1) {
                  return {
                    ...el,
                    parent_id: categoriesWithIdGlobal.find((c) => c.idCat === el.parent_id)
                      ?.idGlobal,
                  };
                }
                return el;
              });
              const ProductsWithIdGlobal = data?.orderJson.items.map((el) => {
                return {
                  ...el,
                  idGlobal: el.idGlobal ? el.idGlobal : uuidv4(),
                };
              });

              const optionsItemsWithIdGlobal = data?.orderJson?.options.map((el) => {
                if (el.items) {
                  const updatedItems = el.items.map((item) => {
                    return {
                      ...item,
                      idGlobal: item.idGlobal ? item.idGlobal : uuidv4(),
                    };
                  });
                  return {
                    ...el,
                    idGlobal: el.idGlobal ? el.idGlobal : uuidv4(),
                    items: updatedItems,
                  };
                }
                return {
                  ...el,
                  idGlobal: el.idGlobal ? el.idGlobal : uuidv4(),
                };
              });

              const promotionsWithIdGlobal = data?.orderJson.promotions.map((el) => {
                return {
                  ...el,
                  idGlobal: el.idGlobal ? el.idGlobal : uuidv4(),
                };
              });
              const cartesWithIdGlobal = data?.orderJson.cartes.map((el) => {
                return {
                  ...el,
                  idGlobal: el.idGlobal ? el.idGlobal : uuidv4(),
                };
              });
              const updatedInternElementsInProduct = ProductsWithIdGlobal.map((el) => {
                return {
                  ...el,
                  idCat: categoriesWithIdGlobal.find((c) => c.idCat === el.idCat)?.idGlobal,
                  additionals: el.additionals.map(
                    (el1) => ProductsWithIdGlobal.find((it) => it.idItem === el1)?.idGlobal,
                  ),
                  options: el.options.map((el1) => ({
                    ...el1,
                    idSupplement: optionsItemsWithIdGlobal.find((it) => it.id === el1.idSupplement)
                      ?.idGlobal,
                  })),
                  ingredients: el.ingredients.map((el1) => ({
                    ...el1,
                    id_product: el.idGlobal,
                    id_ingredient: []
                      .concat(
                        ...optionsItemsWithIdGlobal
                          .filter((op) => op.is_ingredient && op.items.length !== 0)
                          .map((op) => op.items),
                      )
                      .find((it) => it.id === el1.id_ingredient)?.idGlobal,
                  })),
                  daysOn: el.daysOn.map((el1) => ({
                    ...el1,
                    id_product: el.idGlobal,
                  })),
                  carte: el.carte.map((el1) => ({
                    ...el1,
                    id_carte: cartesWithIdGlobal.find((c) => c.id === el1.id_carte)?.idGlobal,
                  })),
                  sub_products: el.sub_products.map((el1) => ({
                    ...el1,
                    id_sub_product: ProductsWithIdGlobal.find(
                      (c) => c.idItem === el1.id_sub_product,
                    )?.idGlobal,
                  })),
                  promotions: el.promotions.map((el1) => ({
                    ...el1,
                    id: promotionsWithIdGlobal.find((c) => c.id === el1.id)?.idGlobal,
                    id_product: el.idGlobal,
                  })),
                  prices: el.prices.map((el1) => ({
                    ...el1,
                    id_product: el.idGlobal,
                  })),
                };
              });
              const updatedInternElementsInCartes = cartesWithIdGlobal.map((el) => {
                return {
                  ...el,
                  items: el.items.map((el1) => ({
                    ...el1,
                    id_carte: el.idGlobal,
                    id_item: ProductsWithIdGlobal.find((c) => c.idItem === el1.id_item)?.idGlobal,
                  })),
                };
              });
              const familleOptionsWithIdGlobal = data?.orderJson.groups_sub_options.map((el) => {
                return {
                  ...el,
                  idGlobal: el.idGlobal ? el.idGlobal : uuidv4(),
                };
              });
              const updatedInternElementsInOptions = optionsItemsWithIdGlobal.map((el) => {
                if (el.items) {
                  const updatedItems = el.items.map((item) => {
                    return {
                      ...item,
                      groups_sub_supplement: item.groups_sub_supplement.map(
                        (el1) => familleOptionsWithIdGlobal.find((it) => it.id === el1)?.idGlobal,
                      ),
                    };
                  });
                  return {
                    ...el,
                    items: updatedItems,
                  };
                }
                return {
                  ...el,
                };
              });
              const updatedInternElementsInFamilleOptions = familleOptionsWithIdGlobal.map((el) => {
                return {
                  ...el,
                  sub_supplements: el.sub_supplements.map(
                    (el1) =>
                      []
                        .concat(
                          ...optionsItemsWithIdGlobal
                            .filter((op) => !op.is_ingredient && op.items.length !== 0)
                            .map((op) => op.items),
                        )
                        .find((it) => it.id === el1)?.idGlobal,
                  ),
                };
              });
              const updatedCarteData = {
                orderJson: {
                  ...data?.orderJson,
                  items: updatedInternElementsInProduct,
                  options: updatedInternElementsInOptions,
                  categories: updatedParentIdInCats,
                  cartes: updatedInternElementsInCartes,
                  promotions: promotionsWithIdGlobal,
                  groups_sub_options: updatedInternElementsInFamilleOptions,
                },
              };
              try {
                await updateCart(
                  { campagne: campagneUrl },
                  dispatch,
                  updatedCarteData,
                  currentUser,
                  { withouUpdateStorageFile: true },
                );
              } catch (error) {
                console.log('error while updating!', error);
                //  console.log('looking for the error! 1')
              }
            } catch (error) {
              console.log('errorrrrrrrrrrrrrrrrrrrrrrrr', error);
            }

            ///////:
          })

          ////////////
          .catch((error) => {
            // Rejeter la promesse en cas d'erreur
            reject(error);
          });
      })
      .catch((error) => {
        // Rejeter la promesse en cas d'erreur
        reject(error);
        console.log('Error retrieving the JSON file', error);
      });
  });
};
export const getFranchiseInfo = async (dispatch, currentUser) => {
  let isBlocked=false
  if(!currentUser?.superAdminLogin){
    try {
      const body= {
        "role" : currentUser?.roles , // "admin" , "moderator" ,  "guest"
        "id": Number(currentUser?.id) // id franchise or restau
    }
    const token2=JSON?.parse(localStorage.getItem("user"))?.accessToken
    const res3 = await axios.post(
      "https://api-super-admin.biborne.com/v2/app-biborne-manager/check-suspension",
      body, // The data (body) should be the second parameter
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token2}`
        }
      }
    );
      isBlocked=res3?.data?.isBlocked
      
    } catch (error5) {
      console.log(error5?.request?.status)
      // console.log("first")
      
      if(error5?.request?.status===403){
        handleLogout(dispatch)
      }
      console.log("error while checking check-suspension", error5)
    }
  }
  try {
    dispatch(setCurrentUserInfo(null));
    if (currentUser?.roles === 'admin') {
      try {
        const res = await FranchiseService.get(currentUser?.id);
        dispatch(
          setCurrentUserInfo({
            ...res.data,
            userType:"Franchise" ,
            isBlocked,
          }),
        );
      } catch (error1) {
        dispatch(setCurrentUserInfo(null));
        console.log('Error in admin path', error1);
      }
    }
    else if(currentUser?.roles === 'guest'){
      try {
        const res = await privateRequest?.get(
          `/api/v1/dashboard/findOneGuest/${currentUser.login}`,
        );
        // get info franchise like country, 
        try {
          const res1 = await FranchiseService.get(currentUser?.id);
          dispatch(
          setCurrentUserInfo({
            ...res.data,
            id: res?.data?.franchiseid,
            userType: 'Guest',
            isBlocked,
            countryFranchiseInfo:res1?.data?.countryFranchiseInfo,
            logo:res1?.data?.logo,
            franchise_name:res1?.data?.franchise_name
          }),
        );
        } catch (error6) {
          console.log("error while recupering franchise info", error6)
        }
        
      } catch (error1) {
        dispatch(setCurrentUserInfo(null));
        console.log('Error in admin path', error1);
      }
    } else {
      try {
        const res = await privateRequest.get(
          `/api/v1/restos/findOneRestaurantDetails/${currentUser?.id}`,
        );
        const response = await privateReqApiBackoffice.get(
          `/api/restos/oneresto/${currentUser?.id}`,
        );
        const haseFranchise = response.data?.franchises?.length !== 0;
        const franchisesListe = response?.data?.franchises;
        if(haseFranchise){
          try {
          const franchisePromises=franchisesListe?.map(async(franchise)=>{
             const res2 = await FranchiseService.get(franchise?.id);

            return {
              id:franchise?.id,
              name:res2?.data?.franchise_name
            }
          })
          const franchisesInfo = await Promise.all(franchisePromises);
          dispatch(
            setCurrentUserInfo({
              ...res.data,
              countryFranchiseInfo: res.data.countryInfo,
              userType:  'RestauNormal',
              franchisesInfo:franchisesInfo,
              isBlocked,
            }),
          );
        } catch (error2) {
          console.log("error while recupering franchise info", error2)
          dispatch(
            setCurrentUserInfo({
              ...res.data,
              countryFranchiseInfo: res.data.countryInfo,
              userType:  'RestauNormal',
              isBlocked,
            }),
          );
        }
       
        }else{
          dispatch(
            setCurrentUserInfo({
              ...res.data,
              countryFranchiseInfo: res.data.countryInfo,
              userType: 'MonoRestau',
              isBlocked,
            }),
          );
          
        }
        
      } catch (error3) {
        console.log(error3);
      }
    }
   
  } catch (error) {
    console.error('Unexpected error:', error);
  }
};
export const getChangesLog = async (currentUser, cardUrl) => {
  const collectionName = "change_logs_" + (currentUser?.roles === "admin" ? "Franchises" : "Restos");

  try {
    const q = query(
      collection(db, collectionName),
      where("currentUser", "==", currentUser),
      where("cardUrl", "==", cardUrl)
    );

    const querySnapshot = await getDocs(q);
    const logs = [];
    
    querySnapshot.forEach((doc2) => {
      logs.push({ id: doc2.id, ...doc2.data() });
    });

    return logs;
  } catch (error) {
    console.error("Error while getting ChangesLog:", error);
    return [];
  }
};