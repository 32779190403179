import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
  TableHead,
  Chip,
  Box,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableFooter,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import CircularProgress from '@mui/material/CircularProgress';
import AuthService from '../../../services/auth.service';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import Switch from '../../../components/custum-elements/Switch';
import { timeTable, timeZoneMap } from '../../../data/data';
import { privateRequest } from '../../../requestMethods';

function TablePaginationActions(props) {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const TempsSortieJour = ({ datedebut, datefin, idResto }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const currentUser = AuthService.getCurrentUser();
  const UserRoles = currentUser ? currentUser?.roles : null;
  const [mesRestoParams, setMesRestoParams] = useState([]);
  const [jours, setJours] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const {currentUserInfo}=useSelector(state=>state.currentUserInfo)
  const franchiseCode=currentUserInfo?.countryFranchiseInfo?.code
  const [countryCode, setCountryCode] = useState('GMT'); 
  const [is12HourFormat, setIs12HourFormat] = useState(false);
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jours.length) : 0;
  useEffect(() => {
    setCountryCode(timeZoneMap.find(el=>el.code?.toUpperCase()===franchiseCode?.toUpperCase())?.timeZone)
  }, [franchiseCode])
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const getListeRestoParams = () => {
    privateRequest
      .get(
        `/api/v1/dashboard/findAllRestosGuest/${currentUser?.id_guest}`,
      )
      .then((response) => {
        setMesRestoParams(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (UserRoles === 'guest') {
      getListeRestoParams();
    }
  }, [UserRoles]);
  const retrieveTypeDiscountByRestaurant = () => {
    if (UserRoles === 'guest') {
      privateRequest
        .post(
          `/api/v1/dashboard/FindAllPerformancesTempsSortieByDayGuest/${currentUser?.id}&${datedebut}&${datefin}`,
          mesRestoParams,
        )
        .then((response) => {
          setJours(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      if (jours.length === 0) {
        setIsLoading(true);
      }
      privateRequest
        .get(
          `/api/v1/dashboard/FindAllPerformancesTempsSortieByDay/${currentUser?.id}&${datedebut}&${datefin}&${idResto}`,
        )
        .then((response) => {
          const InitialArray = response.data;
          let id = 1;
          const productsWithId = InitialArray.map((product) => {
            return { ...product, id: id++ };
          });
          const formattedTable = timeTable.map((j) => {
            const [hours, minutes] = j.split(':');
            const date = new Date();
            date.setUTCHours(hours);
            date.setUTCMinutes(minutes);
            const formattedTime = date.toLocaleTimeString('en-US', {
              hour: is12HourFormat ? 'numeric' : '2-digit',
              minute: '2-digit',
              hour12: is12HourFormat,
              timeZone:countryCode,
            });
            return formattedTime==='24:00' ? '00:00' :formattedTime ;
          });
           setJours(
            productsWithId.map((el, i) => {
              return {
                ...el,
                hour_day: formattedTable[i],

              };
            }),
          ); 
          
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  useEffect(() => {
    retrieveTypeDiscountByRestaurant();
  }, [currentUser?.id, datedebut, datefin, mesRestoParams, idResto, countryCode]);

  useEffect(() => {
    const formattedTable = timeTable.map((j) => {
      const [hours, minutes] = j.split(':');
      const date = new Date();
      date.setUTCHours(hours);
      date.setUTCMinutes(minutes);
      const formattedTime = date.toLocaleTimeString('en-US', {
        hour: is12HourFormat ? 'numeric' : '2-digit',
        minute: '2-digit',
        hour12: is12HourFormat,
        timeZone:countryCode,
      });
      return formattedTime==='24:00' ? '00:00' :formattedTime ;
    });
    setJours((prev) =>
      prev.map((el, i) => {
        return {
          ...el,
          hour_day: formattedTable[i],
        };
      }),
    );
  }, [is12HourFormat, countryCode, franchiseCode]);

  const ConvertirMille = (value) => {
    if (value && value > 0) {
      const ValueParseFloat = parseFloat(value);
      const FinalValue = ValueParseFloat.toLocaleString('fr-FR');
      return FinalValue + ' min';
    }
    return '-';
  };
  function hasOtherKeys(entry) {
    return Object.keys(entry).some(key => key !== 'hour_day' && key !== 'id');
  }
  console.log('test=>',jours.findIndex(el=>el.hour_day>='06:00' && hasOtherKeys(el)),jours[jours.findIndex(el=>el.hour_day>='06:00' && hasOtherKeys(el))])
  const data = [].concat(
    ...jours.map((el) => {
      const { id, hour_day, ...restOfEl } = el;
      return Object.values(restOfEl);
    }),
  );
  const maxTempSortie = Math.max(...data);
  const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Box sx={{ pb: 4 }}>
            <Typography variant="h4">Temps de Sortie Par Jour</Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <CustomSelect
              id="unit"
              name="unit"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              fullWidth
              size="small"
              defaultValue="0"
              style={{
                width: '50%',
              }}
            >{
              timeZoneMap.map(t=>(
                             <MenuItem key={t.timeZone} value={t.timeZone}>
                              {t.countryName} <span style={{
                                fontSize:'14px',
                                opacity:'0.7',
                                fontWeight:'300',
                                paddingLeft:"8px"
                              }}>{t.GMTOfsset}</span>
                             </MenuItem>

              ))
            }
            </CustomSelect>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent:"center",
                gap: '20px',
              }}
            >
              <span
                style={{
                  fontWeight: is12HourFormat && 500,
                  transition: 'all .4s ease',
                }}
              >
                12-Hour Format
              </span>
              <Switch
                onClick={() => setIs12HourFormat(!is12HourFormat)}
                choice={is12HourFormat ? 'left' : 'right'}
              />
              <span
                style={{
                  fontWeight: !is12HourFormat && 500,
                  transition: 'all .4s ease',
                }}
              >
                24-Hour Format
              </span>
            </Box>
          </Box>
          {isLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size="50px" />
            </Box>
          )}
          <Box
            sx={{
              overflow: {
                xs: 'auto',
                sm: 'auto',
              },
            }}
          >
            {!isLoading && (
              <Table
                aria-label="custom pagination table"
                sx={{
                  whiteSpace: 'nowrap',
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: '10%' }}>
                      <Typography variant="h6">Heure du jour</Typography>
                    </TableCell>
                    {days.map((day) => (
                      <TableCell sx={{ width: '10%' }}>
                        <Typography variant="h6" align="center">
                          {day}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? jours.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : jours
                  ).map((day) => (
                    <TableRow key={day.hour_day}>
                      <TableCell
                        sx={{
                          padding: 0,
                          // borderBottom: 0,
                          background: (theme) => theme.palette.primary.dark,
                          color: (theme) => theme.palette.primary.light,
                        }}
                      >
                        <Typography color="" variant="h6" fontWeight="400" align="center">
                          {day.hour_day}
                        </Typography>
                      </TableCell>
                      {days.map((el) => (
                        <TableCell sx={{ p: 0 }}>
                          <Chip
                            sx={{
                              // 255, 123, 0,
                              background:
                                day[el?.toLowerCase()] && day[el?.toLowerCase()] > 0
                                  ? `rgb(255, 123, 0,${1-day[el?.toLowerCase()] / maxTempSortie})`
                                  : 'white',
                              fontSize: '.8rem',
                              color: `rgb(0, 0, 0, ${
                                day[el?.toLowerCase()] / maxTempSortie / 2 + 0.4
                              })`,
                              borderRadius: '0',
                              width: '100%',
                            }}
                            size=""
                            label={ConvertirMille(day[el?.toLowerCase()])}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={6}
                      count={jours.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputprops: {
                          'aria-label': 'rows per page',
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default TempsSortieJour;

TempsSortieJour.propTypes = {
  datedebut: PropTypes.string.isRequired,
  datefin: PropTypes.string.isRequired,
  idResto: PropTypes.string.isRequired,
};
