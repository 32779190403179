import React, { useState } from 'react';
import dateFormat from 'dateformat';
import 'moment/locale/fr';
import frLocale from 'date-fns/locale/fr';
import { Box, Grid, MenuItem } from '@material-ui/core';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@material-ui/lab';
import CustomTextField from '../../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../../components/forms/custom-elements/CustomFormLabel';
import CustomSelect from '../../../components/forms/custom-elements/CustomSelect';
import PageContainer from '../../../components/container/PageContainer';
import FranchiseEquipement from './FranchiseEquipement';
import useEtablissements from '../../../redux/custom-hooks/useEtablissements';

const date = new Date();
const firstDay = dateFormat(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-mm-dd');
const lastDay = dateFormat(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-mm-dd');

const Zequipement = () => {

  const [datedebut, setDatedebut] = useState(firstDay);
  const [datefin, setDatefin] = useState(lastDay);
  const {etablissements}=useEtablissements()

  const initialAdamState = {
    restoId: '0',
  };
  const [adams, setAdams] = useState(initialAdamState);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAdams({ ...adams, [name]: value });
  };

  return (
    <Box>
      <PageContainer title="Z Équipement" description="Z Équipement">
        <Grid container spacing={2}>
          <Grid item xs={2} sm={12} md={4}>
            <CustomFormLabel htmlFor="datedebut">Date Debut</CustomFormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
              <DatePicker
                value={datedebut}
                onChange={(newValue2) => {
                  setDatedebut(dateFormat(newValue2, 'yyyy-mm-dd'));
                }}
                renderInput={(params) => (
                  <CustomTextField
                    size="small"
                    {...params}
                    fullWidth
                    id="datedebut"
                    sx={{
                      '& .MuiSvgIcon-root': {
                        width: '18px',
                        height: '18px',
                      },
                      '& .MuiFormHelperText-root': {
                        display: 'none',
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={2} sm={12} md={4}>
            <CustomFormLabel htmlFor="datefin">Date Fin</CustomFormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
              <DatePicker
                value={datefin}
                onChange={(newValueDf) => {
                  setDatefin(dateFormat(newValueDf, 'yyyy-mm-dd'));
                }}
                renderInput={(params) => (
                  <CustomTextField
                    size="small"
                    {...params}
                    fullWidth
                    id="datefin"
                    sx={{
                      '& .MuiSvgIcon-root': {
                        width: '18px',
                        height: '18px',
                      },
                      '& .MuiFormHelperText-root': {
                        display: 'none',
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={4} sm={12} md={4}> 
            <CustomFormLabel htmlFor="restoId">Nom Restaurant ({etablissements?.length!==0 && etablissements?.length})</CustomFormLabel>
            <CustomSelect
              labelId="restoId"
              id="restoId"
              name="restoId"
              value={adams.restoId}
              onChange={handleInputChange}
              fullWidth
              size="small"
            >
              <MenuItem sx={{ fontSize: 12 }}>Tous les restaurants</MenuItem>
              {etablissements.map((etablissement) => (
                <MenuItem
                  sx={{ fontSize: 12, textTransform: 'uppercase' }}
                  key={etablissement.restos.id}
                  value={etablissement.restos.id}
                >
                  {etablissement.restos.nom}
                </MenuItem>
              ))}
            </CustomSelect>
          </Grid>

        </Grid>
      </PageContainer>
      <FranchiseEquipement datedebut={datedebut} datefin={datefin} idResto={adams.restoId} />
    </Box>
  );
};

export default Zequipement;
