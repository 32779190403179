import React from 'react';
import DeleteModal from './DeleteModal';
import RestoreModal from './RestoreModal';
import DeleteRelation from './DeleteRelation';
import DeleteCampagne from './DeleteCampagne'
import { useModal } from '../../context/ModalContext';

const MoadalLookup = {
  DeleteModal, 
  RestoreModal, 
  DeleteRelation,
  DeleteCampagne
};

const ModalManager = () => {
  const { modal, closeModal } = useModal();

  if (!modal) return null;

  const Modal = MoadalLookup[modal.name];

  return <Modal 
  onClose={closeModal}
  {...modal.props}
   />;
};

export default ModalManager;
